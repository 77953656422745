import React from 'react'
import Image from 'next/image'

import { Text, Link } from '@/components/common'
import { Layout, Section, Rows } from '@/components/layout'
import { URLS } from '@/constants'
import routes from '@/routes'
import fourZeroFourImg from '@/assets/404/404.png'

import styles from './404.module.scss'

const pageId = 'notFound'

export default function Custom404() {
  return (
    <Layout.Content>
      <Section className={styles.content} marginBottom={32}>
        <Rows center className={styles.picture404} gap={64} textCenter>
          <Image alt="" className="image-center" src={fourZeroFourImg} />
          <Text.H2 messageId="pages.notFound.h2" />
        </Rows>
        <div className={styles.divider} />
        <Rows center className={styles.links} gap={24} textCenter>
          <Text.H4 bold messageId="pages.notFound.popular" secondary />
          <Link
            external
            messageId="pages.notFound.links.support"
            openTab
            to={URLS.support.home}
          />
          <Link
            messageId="pages.notFound.links.plansAndPricing"
            to={routes.pricing}
          />
          <Link
            messageId="pages.notFound.links.blog"
            to={routes.blog}
          />
          <Text.P messageProps={{
            id: 'pages.notFound.links.cannotFind',
            values: {
              link: <Link
                className={styles.link}
                external
                messageId="pages.notFound.links.contactUs"
                openTab
                to={URLS.support.supportTeam}
              />,
            },
          }}
          />
        </Rows>
      </Section>
    </Layout.Content>
  )
}

Custom404.layoutProps = {
  pageId,
  shouldRobotsIndex: false,
}
